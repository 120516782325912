import React from 'react'
import { Link } from "gatsby"

import styled from "@emotion/styled"
import { css } from "@emotion/core"

import Layout from "../components/layout.js"
import SEO from "../components/seo"

const Grid = styled.section`
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-gap: 1em;
`;

const Project = styled.div`
    background-color: var(--background);
    border: 0.1em solid var(--primary);
    box-shadow: 0.1em 0.1em var(--primary);
    transition: 0.25s var(--transition);
    padding: 1em;
    &:hover {
        transform: translate(-0.5em, -0.5em);
        box-shadow: 0.6em 0.6em var(--primary);
    }
`;

export default () => (
    <Layout page="portfolio">
        <SEO title='Portfolio' description="Isabelle Wagenvoord's Portfolio"/>
        <h1>👩🏻‍💻 Projects</h1>
        <h2>Completed Projects</h2>
            <Grid>
                <Project style={css`grid-column: first / span 2`}>
                    <h3>
                        <a target="_blank" href="https://drive.google.com/file/d/1x4FD5c5qnigZDDSjk13epBBqIuKgiHlw/view?usp=sharing">
                            AWS Minecraft Server
                        </a>
                    </h3>
                    <p>
                        This was the final project for my third cloud computing class, CS 79C. 
                        The main challenge was getting a Minecraft server up and running on a 
                        single t2.micro instance. The instance
                        finally managed once I started using <a href="https://papermc.io/">PaperMC</a>.
                        I also automated the servers' startup and end times to run only during 
                        the weekend.
                    </p>
                </Project>
                <Project>
                    <h3>
                        <a target="_blank" href="https://drive.google.com/file/d/1L9BtXquX0yDNfI4D5Kyq4iqVixjC3-cS/view?usp=sharing">
                            Engineering Ethics
                        </a>
                    </h3>
                    <p>
                        In my junior year of high school, I was required create and present a five-minute 
                        slide presentation on an engineering disaster. 
                    </p>
                </Project>
            </Grid>
        <h2>🔨 In Progress</h2>
        <Grid>
            <Project>
                <h3>Chatbot</h3>
                <p>
                    An Angular component that interfaces with an Amazon Lex chatbot.
                    More details to come!
                </p>
            </Project>
            <Project>
                <h3>
                    <Link to="/pltw#about">
                        PLTW Capstone Project
                    </Link>
                </h3>
                <p>
                    A group project to address homelessness in Santa Monica. We are
                    currently still researching. Right now the linked document is only
                    visible to people in my school district. See more details about
                    PLTW Capstone <Link to="/pltw">here</Link>.
                </p>
            </Project>
        </Grid>
    </Layout>
)